import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Hr, Icon, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { IoIosCheckmarkCircle } from "react-icons/io";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Home | GrandSlam Courts
			</title>
			<meta name={"description"} content={"Meistern Sie Ihren Aufschlag unter unseren Lichtern"} />
			<meta property={"og:title"} content={"Home | GrandSlam Courts"} />
			<meta property={"og:description"} content={"Meistern Sie Ihren Aufschlag unter unseren Lichtern"} />
			<link rel={"shortcut icon"} href={"https://zenitorfax.com/img/3176367.png"} type={"image/x-icon"} />
		</Helmet>
		<Components.Header />
		<Section overflow-x="hidden" overflow-y="hidden" quarkly-title="Hero-6">
			<Override slot="SectionContent" width="100%" flex="1 1 0%" max-width="100%" />
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				position="static"
				lg-flex-direction="column"
			>
				<Box
					min-width="100px"
					min-height="100px"
					width="55%"
					padding="140px 80px 140px 80px"
					position="static"
					lg-width="100%"
					lg-padding="80px 30px 80px 30px"
					sm-padding="80px 30px 40px 30px"
				>
					<Text margin="0px 0px 20px 0px" font="normal 700 56px/1.2 --fontFamily-sans" sm-font="normal 700 42px/1.2 --fontFamily-sans">
						GrandSlam Courts
					</Text>
					<Text margin="0px 0px 50px 0px" font="--base">
					Betreten Sie GrandSlam Courts, wo Tennisbegeisterte aller Niveaus zusammenkommen, um ihre Leidenschaft für das Spiel zu feiern. Egal, ob Sie Ihre Fähigkeiten verbessern, an herausfordernden Spielen teilnehmen oder gerade erst Ihre Tenniskarriere beginnen, unsere Einrichtungen bieten die perfekte Kulisse für die Entwicklung jedes Spielers. GrandSlam ist Ihr lokaler Mittelpunkt im Tennis und bietet eine überzeugende Atmosphäre und erstklassige Spielbedingungen.
					</Text>
					<Box
						min-width="10px"
						min-height="10px"
						display="flex"
						sm-flex-direction="column"
						sm-align-items="stretch"
					>
						<Button
							margin="0px 15px 0px 0px"
							padding="12px 28px 12px 28px"
							background="--color-green"
							border-radius="8px"
							font="normal 400 17px/1.5 --fontFamily-sans"
							sm-margin="0px 0 15px 0px"
							border-width="2px"
							border-style="solid"
							border-color="--color-green"
							hover-color="--darkL1"
							hover-background="rgba(63, 36, 216, 0)"
							transition="background-color 0.2s ease 0s"
							type="link"
							text-decoration-line="initial"
							href="/contact-us"
							sm-text-align="center"
							sm-display="inline-block"
						>
							Besuchen Sie uns
						</Button>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="758px"
					display="flex"
					margin="0px -50px 0px 0px"
					position="static"
					bottom="-298px"
					height="716px"
					left="614px"
					right="-52px"
					top="-28px"
					flex="1 1 0%"
					lg-margin="0px 0 0px 0px"
					lg-align-self="center"
					lg-padding="0px 0px 80px 0px"
					sm-flex="1 1 0%"
					sm-align-self="auto"
					sm-width="100%"
					sm-padding="0px 0px 40px 0px"
				>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						margin="80px 0 0 25px"
						width="33%"
						lg-margin="80px 0 0 0"
						sm-margin="50px 0 0 0"
					>
						<Image
							src="https://zenitorfax.com/img/1.jpg"
							display="block"
							height="300px"
							border-radius="25px"
							object-fit="cover"
							width="100%"
							sm-height="200px"
							sm-width="200px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						flex-direction="column"
						justify-content="center"
						margin="80px 0 0 25px"
						width="33%"
						sm-align-self="center"
						sm-margin="50px 0 0 15px"
					>
						<Image
							src="https://zenitorfax.com/img/2.jpg"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
						/>
						<Image
							src="https://zenitorfax.com/img/3.jpg"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
						/>
					</Box>
					<Box
						min-width="100px"
						min-height="100px"
						display="flex"
						align-items="center"
						flex-direction="column"
						margin="0 0 0 25px"
						width="33%"
						justify-content="flex-start"
						sm-margin="0 0 0 15px"
					>
						<Image
							src="https://zenitorfax.com/img/4.jpg"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
						/>
						<Image
							src="https://zenitorfax.com/img/5.jpg"
							display="block"
							max-width="100%"
							height="300px"
							object-fit="cover"
							border-radius="15px"
							margin="0px 0px 15px 0px"
							sm-height="200px"
							sm-width="200px"
						/>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section background="url(https://zenitorfax.com/img/6.jpg) center/cover" padding="140px 0" sm-padding="40px 0" quarkly-title="About-16">
			<Override slot="SectionContent" align-items="center" justify-content="center" sm-min-width="280px" />
			<Box
				padding="50px 80px 50px 50px"
				color="--dark"
				display="flex"
				flex-direction="column"
				align-items="center"
				background="#ffffff"
				sm-padding="50px 24px 50px 24px"
				width="80%"
			>
				<Text margin="0 0 16px 0" as="h2" font="--headline2">
				Über GrandSlam Courts
				</Text>
				<Hr
					min-height="10px"
					margin="0px 0px 0px 0px"
					border-color="--color-dark"
					border-width="3px 0 0 0"
					max-width="50px"
					width="100%"
				/>
				<Text font="--base" text-align="center">
				Bei GrandSlam Courts widmen wir uns der Förderung des Tennissports für alle Altersgruppen und Leistungsniveaus. Unsere Plätze werden nach den höchsten Standards gepflegt, um sicherzustellen, dass jedes Spiel ebenso unterhaltsam wie wettbewerbsorientiert ist. Mit unserem freundlichen Personal und unserer lebendigen Tennis-Community ist GrandSlam Courts eine Säule des Sportsgeists und ein Ort, an dem der Tennistraditionen geehrt und gepflegt werden.
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" quarkly-title="Content-8">
			<Override
				slot="SectionContent"
				padding="0px 0 0px 0px"
				lg-padding="0px 0 0px 0px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				lg-grid-template-columns="1fr"
				align-items="start"
				width="100%"
				min-width="100%"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 60px 0px 60px"
				lg-margin="0px 0px 50px 0px"
				sm-padding="0px 25px 0px 25px"
			>
				<Text margin="0px 0px 30px 0px" font="normal 600 42px/1.2 --fontFamily-sans">
				Warum GrandSlam Courts?
				</Text>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--green"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="--base" color="#505257">
						<Strong>
						Außergewöhnliche Platzoberflächen: 
						</Strong>
						{" "}Unsere Oberflächen werden für optimale Leistung und Haltbarkeit ausgewählt und bieten gleichbleibendes Spiel bei jedem Wetter. 
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--green"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="--base" color="#505257">
						<Strong>
						Strategische Lage: 
						</Strong>
						{" "}Die leicht zugänglichen GrandSlam Courts sind Ihr lokaler Tennis-Rückzugsort für das tägliche Spiel oder größere Trainingseinheiten.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--green"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="--base" color="#505257">
						<Strong>
						Auf den Spieler ausgerichtete Annehmlichkeiten: 
						</Strong>
						{" "}Wir bieten moderne Annehmlichkeiten, die auf die Bedürfnisse von Tennisspielern zugeschnitten sind und Ihr Erlebnis auf und neben dem Platz verbessern.
					</Text>
				</Box>
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					font="normal 300 18px/1.5 --fontFamily-sansHelvetica"
					margin="0px 0px 35px 0px"
				>
					<Icon
						category="io"
						icon={IoIosCheckmarkCircle}
						size="24px"
						color="--green"
						margin="0px 10px 0px 0px"
						padding="5px 0px 0px 0px"
					/>
					<Text margin="0px 0px 0px 0px" font="--base" color="#505257">
						<Strong>
						Community und Networking:
						</Strong>
						{" "}GrandSlam ist die Heimat einer großartigen Community aus Spielern und Fans und somit ein großartiger Ort, um Ihr Netzwerk und Ihr Spiel aufzubauen.
					</Text>
				</Box>
				<Text margin="0px 0px 0 0px" font="normal 300 18px/1.5 --fontFamily-sans" color="#505257">
				Begeben Sie sich bei GrandSlam Courts auf Ihre Tennisreise. Besuchen Sie uns für das Spiel, bleiben Sie für die Gemeinschaft. Entdecken Sie, warum so viele uns als ihre bevorzugte Tennisdestination wählen. Bereit zum Spielen?
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-3">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="65%"
				align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				justify-content="center"
				lg-width="100%"
			>
				<Box
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
					height="auto"
					padding="0px 0px 70% 0px"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://zenitorfax.com/img/7.jpg"
						object-fit="cover"
						left={0}
						top={0}
						right={0}
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="35%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
			>
				<Box
					transform="translateY(0px)"
					width="100%"
					overflow-y="hidden"
					position="relative"
					hover-transform="translateY(-10px)"
					padding="0px 0px 65% 0px"
					height="auto"
					overflow-x="hidden"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						bottom={0}
						src="https://zenitorfax.com/img/8.jpg"
						width="100%"
						left={0}
						right={0}
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top={0}
					/>
				</Box>
				<Text
					margin="24px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Besuchen Sie uns bei GrandSlam Courts
				</Text>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"652fbf0fdbe26f0020fd0d1b"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});